export default [
    {
        skills: [
            {
                name: 'html',
                img: require('./assets/html-logo.png')
            },
            {
                name: 'css',
                img: require('./assets/css-logo.png')
            },
            {
                name: 'sass',
                img: require('./assets/sass-logo.png')
            },
            {
                name: 'javascript',
                img: require('./assets/js-logo.png')
            },
            {
                name: 'typescript',
                img: require('./assets/ts-logo.png')
            },
            {
                name: 'react js',
                img: require('./assets/react-logo.png')
            },
            // {
            //     name: 'python',
            //     img: require('./assets/python-logo.png')
            // },
            {
                name: 'git',
                img: require('./assets/git-logo.png')
            },
            {
                name: 'github',
                img: require('./assets/github-logo-light.png')
            },  
        ],
        projects: [
            {
                title: 'BIT INFO NEPAL',
                description: "A Platform for BIT TU Students.",
                url: 'https://www.bitinfonepal.com/',
                gif: require('./assets/bitinfo.gif'),
                tags: ['react js', 'sass', 'firebase']   
            },
            {
                title: 'Space Tourism',
                description: "A Challenge from Fronend Mentor",
                url: 'https://space-tourism-frontend-mentor-jr.netlify.app/',
                gif: require('./assets/space.gif'),
                tags: ['react js', 'sass']   
            },
            {
                title: 'Trafalgar Landing',
                description: "Trafalgar Landing Page",
                url: 'https://yipl-frontend-challenge.netlify.app/',
                gif: require('./assets/trafalgar.gif'),
                tags: ['html', 'sass', 'javascript']   
            },           
            {
                title: 'Tip Calculator App',
                description: "A Challenge from Frontend Mentor",
                url: 'https://prajwol-shrestha.github.io/Front%20End%20Mentor/tip-calculator-app-main/',
                gif: require('./assets/tip.gif'),
                tags: ['html','javascript', 'css']   
            },
            {
                title: 'Sunny Side Agency Landing Page',
                description: "A Challenge from Frontend Mentor",
                url: 'https://prajwol-shrestha.github.io/Front%20End%20Mentor/sunnyside-agency-landing-page-main/',
                gif: require('./assets/sunnyside-landing.gif'),
                tags: ['javascript', 'css']   
            },
            {
                title: 'Flyo Landing Page',
                description: "A Challenge from Frontend Mentor",
                url: 'https://prajwol-shrestha.github.io/Front%20End%20Mentor/fylo-dark-theme-landing-page-master/',
                gif: require('./assets/flyo.gif'),
                tags: ['html','javascript', 'css']   
            }, 
        ]
    },

]